<template>
  <div class="inventoryReportYearly">
    <h1>库存月报表</h1>
    <el-form :inline="true">
      <el-form-item label="年份">
        <el-date-picker :clearable="false" v-model="searchParam.year" type="year" style="width: 110px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for="storageItem in show.storageList" :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column label="日期" width="180" show-overflow-tooltip>
        <template #default="scope">
          <el-text>{{ scope.row.dateGe + '~' + scope.row.dateLe }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="inTruckQuantity" label="入库车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ scope.row.inTruckQuantity }}
        </template>
      </el-table-column>
      <el-table-column prop="inQuantity" label="入库数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.inQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="outTruckQuantity" label="出库车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ scope.row.outTruckQuantity }}
        </template>
      </el-table-column>
      <el-table-column prop="outQuantity" label="出库数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.outQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="residueQuantity" label="当月库存结余" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.residueQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="summayrResidueQuantity" label="累计库存结余" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.summayrResidueQuantity) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  inTruckQuantity: null,
  inQuantity: null,
  outTruckQuantity: null,
  outQuantity: null,
})

const show = reactive({
  selected: [],
  storageList: []
})

api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.year = new Date()
  searchParam.storage = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.storage) {
    ElMessage.error('请选择仓储')
    return
  }
  if (!searchParam.year) {
    ElMessage.error('请选择年份')
    return
  }
  data.list = []
  const params = {}
  params.storage = searchParam.storage
  const firstDay = new Date(searchParam.year.getFullYear(), 0, 1)
  const lastDay = new Date(searchParam.year.getFullYear() + 1, 0, 0)
  for (const date = firstDay; date <= lastDay; date.setMonth(date.getMonth() + 1)) {
    params.dateGe = util.parseTime(date, '{y}-{m}-{d}')
    const dateLe = new Date(date)
    dateLe.setMonth(date.getMonth() + 1)
    dateLe.setDate(date.getDate() - 1)
    params.dateLe = util.parseTime(dateLe, '{y}-{m}-{d}')
    api.get('/backend/statistics/inventoryReportByStorage', { params: params }).then(res => {
      data.list.push(res)
      data.list.sort((a, b) => {
        return new Date(a.dateGe).getTime() - new Date(b.dateGe).getTime()
      })
    })
  }
}

const summary = () => {
  const list = data.list
  let inTruckQuantity = 0
  let inQuantity = 0
  let outTruckQuantity = 0
  let outQuantity = 0
  list.forEach(item => {
    inTruckQuantity = math.plus(inTruckQuantity, item.inTruckQuantity)
    inQuantity = math.plus(inQuantity, item.inQuantity)
    outTruckQuantity = math.plus(outTruckQuantity, item.outTruckQuantity)
    outQuantity = math.plus(outQuantity, item.outQuantity)
  })
  return ['合计', inTruckQuantity, math.formatNumber(inQuantity), outTruckQuantity, math.formatNumber(outQuantity)]
}
</script>

<style lang="less"></style>